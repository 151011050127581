import Vue from "vue";
import Vuex from "vuex";
import Cookie from "js-cookie";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {},
  },
  mutations: {
    getUserInfo(state, data) {
      state.userInfo = data;
    },
  },
  actions: {
    logout() {
      const DOMAIN = process.env.VUE_APP_DOMAIN;
      Cookie.remove("token", { domain: DOMAIN });
      Cookie.remove("uname", { domain: DOMAIN });
      Cookie.remove("uid", { domain: DOMAIN });
      const referer = window.location.href;
      const loginUrl = `https://my` + process.env.VUE_APP_DOMAIN;
      window.location.href = loginUrl + "/?referer=" + referer;
    },
  },
  modules: {},
});
