import axios from "axios";

import cookie from "js-cookie";
import { Message } from "element-ui";
const axiosInstance = axios.create();
// axios.defaults.withCredentials = true;
axiosInstance.defaults.timeout = 20000;

// 添加一个请求拦截器（ 一般用于鉴权 )
axiosInstance.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    const TOKEN = cookie.get("token");
    if (TOKEN) {
      newConfig["headers"]["Authorization"] = "Bearer " + TOKEN;
    }
    return newConfig;
  },
  (error) => Promise.reject(error)
);

// 全局错误处理
const handleErrorRequest = (error) => {
  const { response } = error;
  const status = response ? response.status : 408;

  if (response) {
    const { data } = response;
    // const { errors } = data;
    const message = data.msg || "服务器发送错误，请稍后再试";
    if (status === 401) {
      // Message.error(message);
    } else if (status === 422 || status === 423 || status === 429) {
      // let errorObj = errors ? errors : data;
      // Object.keys(errorObj).forEach(errorKey => {
      //   const errorValue = errorObj[errorKey].join("\n");
      //   Message.error(errorValue);
      // });
    } else if (status === 500) {
      Message.error(message);
    }
  } else {
    // Message.error("网络超时");
  }
};

// 添加响应拦截器
axiosInstance.interceptors.response.use(
  (res) => {
    // 兼容包了一层 { code, data } 的情况
    switch (true) {
      // data.code 返回不等于200 代表业务错误
      case res.data.code === 2000:
        return res.data.data;
      case res.data.code === 200:
        return res.data.data;
      case res.data.error_code === 0:
        return res.data.data;
      case res.data.error_code === "course_400111":
        return res.data;
      case !!res.data.error_code:
        Message.error({
          dangerouslyUseHTMLString: true,
          message: `<pre>${res.data.msg}</pre>`,
        });
        return Promise.reject(res.data);
      case !!res.data.code:
        return Promise.reject(res.data);
      default:
        return res.data;
    }
  },
  (error) => {
    handleErrorRequest(error);
    return Promise.reject(error);
  }
);

/* [请求库]
 ** @params url         { string }   @default => '' [接口地址]
 ** @params data/params { object }   @default => {} [发送数据]
 ** @params config      { object }   配置
 */

export default {
  post(url = "", data = {}, config = {}) {
    return axiosInstance.post(url, data, config);
  },

  put(url = "", data = {}, config = {}) {
    return axiosInstance.put(url, data, config);
  },

  get(url = "", params = {}, config = {}) {
    const OPTIONS = Object.assign({ params }, config);
    return axiosInstance.get(url, OPTIONS);
  },

  delete(url = "", params = {}, config = {}) {
    const OPTIONS = Object.assign({ params }, config);
    return axiosInstance.delete(url, OPTIONS);
  },
};
