const routes = [
  {
    path: "/",
    component: () => import("../components/TheLayout.vue"),
    redirect: { name: "JkxyVip" },
    children: [
      {
        path: "pay/:id",
        name: "JkxyPay",
        component: () => import("../views/Pay.vue"),
      },
      {
        path: "vip",
        name: "JkxyVip",
        component: () => import("../views/Vip.vue"),
      },
      {
        path: "order",
        name: "JkxyOrder",
        component: () => import("../views/Order.vue"),
      },
    ],
  },
];

export default routes;
