import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import Store from "@/store/index";
// import { Message } from "element-ui";
import authService from "@/global/service/auth";
import cookie from "js-cookie";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === from.name) {
      return true;
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.firstInit = false;
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (to.meta.title) document.title = to.meta.title;
  const TOKEN = cookie.get("token");
  if (!TOKEN) {
    const referer = window.location.href;
    const ENV_PREFIX = process.env.NODE_ENV === "uat" ? "uat-" : "";
    window.location.href =
      `https://${ENV_PREFIX}my` +
      process.env.VUE_APP_DOMAIN +
      "/?referer=" +
      referer;
    return;
  }
  if (!router.firstInit) {
    router.firstInit = true;
    const userInfo = await authService.userProfile();
    Store.commit("getUserInfo", userInfo);
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
