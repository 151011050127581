const PAY_PREFIX = `https://api` + process.env.VUE_APP_DOMAIN + `/api/pay`;
const MY_PREFIX = `https://api` + process.env.VUE_APP_DOMAIN + `/api/passport`;
export default {
  vipPrices: `${PAY_PREFIX}/vip-prices`,
  paySelect: `${PAY_PREFIX}/select`,
  aliPay: `${PAY_PREFIX}/alipay/webpay`,
  wechatPay: `${PAY_PREFIX}/wechat/qrcode`,
  orders: `${PAY_PREFIX}/orders`,
  ordersId: (id) => `${PAY_PREFIX}/orders/${id}`,
  userProfile: `${MY_PREFIX}/auth/user-profile`,
};
