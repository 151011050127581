import API from "@/global/request/api";
import axios from "@/global/request/axios";

const authService = {
  userProfile() {
    return axios.get(API.userProfile);
  },
};

export default authService;
